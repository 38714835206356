import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const universityManagementSlide = createSlice({
    name: "universityManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,

        updateUniversityStatusSuccess: false,
        updateUniversityStatusFail: false,

        editUniversityManagementSuccess: false,
        editUniversityManagementFail: false,
        editUniversityManagementErrorDetail: null,
    },
    reducers: {
        doGetUniversityManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
        },
        doGetUniversityManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetUniversityManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetUniversityManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateUniversityStatusSuccess: (state, action) => {
            state.updateUniversityStatusSuccess = true;
            state.updateUniversityStatusFail = false;
        },
        doUpdateUniversityStatusFail: (state, action) => {
            state.updateUniversityStatusSuccess = false;
            state.updateUniversityStatusFail = true;
			state.errorDetailData = action.payload;
        },
        resetUniversityManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
        resetUpdateUniversityStatusStateSuccess: (state, action) => {
            state.updateUniversityStatusSuccess = action.payload;
            state.updateUniversityStatusFail = action.payload;
        },
        doEditUniversityManagementSuccess: (state, action) => {
            state.editUniversityManagementSuccess = true;
            state.editUniversityManagementFail = false;
			state.editUniversityManagementErrorDetail = null;
        },
        doEditUniversityManagementFail: (state, action) => {
            state.editUniversityManagementSuccess = false;
            state.editUniversityManagementFail = true;
			state.editUniversityManagementErrorDetail = action.payload.data;
        },
        resetEditUniversityManagementState: (state, action) => {
            state.editUniversityManagementSuccess = false;
            state.editUniversityManagementFail = false;
			state.editUniversityManagementErrorDetail = null;
        },
    },
});

export const doGetUniversityManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/university-management/list?page=${param?.page}&size=${param?.limit}`;
        if (param?.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterMo') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetUniversityManagementListSuccess(response?.data));
    } catch (error) {
        dispatch(doGetUniversityManagementListFail(error?.response));
    }
};

export const doGetUniversityManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/university-management/${param?.university_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetUniversityManagementDetailSuccess(response?.data));
    } catch (error) {
        dispatch(doGetUniversityManagementDetailFail(error?.response));
    }
};

export const doUpdateUniversityStatus = (param, universityId) => async (dispatch) => {
    try {
        const url = `/api/admin/university-management/${universityId}/status`;
        const response = await axios.put(url, param);
        dispatch(doUpdateUniversityStatusSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateUniversityStatusFail(error.response));
    }
};

export const resetUniversityManagementState = () => async (dispatch) => {
    dispatch(resetUniversityManagementStateSuccess(false));
};

export const resetUpdateUniversityStatusState = () => async (dispatch) => {
    dispatch(resetUpdateUniversityStatusStateSuccess(false));
};

export const doEditUniversityManagement = (param, universityId) => async (dispatch) => {
    try {
        const url = `/api/admin/university-management/${universityId}/edit`;
        const response = await axios.put(url, param);
        dispatch(doEditUniversityManagementSuccess(response.data));
    } catch (error) {
        dispatch(doEditUniversityManagementFail(error.response));
    }
};

export const doResetEditUniversityManagementState = () => async (dispatch) => {
    dispatch(resetEditUniversityManagementState());
};

export const {
    doGetUniversityManagementListSuccess,
    doGetUniversityManagementListFail,
    doGetUniversityManagementDetailSuccess,
    doGetUniversityManagementDetailFail,
    doUpdateUniversityStatusSuccess,
    doUpdateUniversityStatusFail,
    resetUniversityManagementStateSuccess,
    resetUpdateUniversityStatusStateSuccess,
    doEditUniversityManagementSuccess,
    doEditUniversityManagementFail,
    resetEditUniversityManagementState,
} = universityManagementSlide.actions;

export const getUniversityManagementList = (state) => state.universityManagement?.listData;
export const getUniversityManagementListPage = (state) => state.universityManagement?.page;
export const getUniversityManagementListPageCount = (state) => state.universityManagement?.pageCount;
export const getUniversityManagementDetail = (state) => state.universityManagement?.detailData;
export const getUniversityManagementErrorDetailData = (state) => state.universityManagement?.errorDetailData;

export const getUpdateUniversityStatusSuccess = (state) => state.universityManagement?.updateUniversityStatusSuccess;
export const getUpdateUniversityStatusFail = (state) => state.universityManagement?.updateUniversityStatusFail;

export const getEditUniversityManagementSuccess = (state) => state.universityManagement?.editUniversityManagementSuccess;
export const getEditUniversityManagementFail = (state) => state.universityManagement?.editUniversityManagementFail;
export const getEditUniversityManagementErrorDetail = (state) => state.universityManagement?.editUniversityManagementErrorDetail;

export default universityManagementSlide.reducer;
