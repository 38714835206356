import React from "react";

const StudentManagementList = React.lazy(() => import('src/pages/student_management/StudentManagementList'));
const ViewStudentManagement = React.lazy(() => import('src/pages/student_management/ViewStudentManagement'));

const routes = [
    { path: '/dashboard/student-management-list/view', withParam: true, exact: true, name: "View", element: ViewStudentManagement },
    { path: '/dashboard/student-management-list', name: 'Student Management', element: StudentManagementList },
];

export default routes;