import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import core from "src/reducers/reducer_core";
import myProfile from "src/reducers/reducer_my_profile";
import forgotPassword from "src/reducers/reducer_forgot_password";
import employerManagement from "src/reducers/reducer_employer_management";
import studentManagement from "src/reducers/reducer_student_management";
import universityManagement from "src/reducers/reducer_university_management";
import jobManagement from "src/reducers/reducer_job_management";
import membershipManagement from "src/reducers/reducer_membership_management";
import subscriptionManagement from "src/reducers/reducer_subscription_management";

const appReducer = combineReducers({
    core,
    myProfile,
    forgotPassword,
    employerManagement,
    studentManagement,
    universityManagement,
    jobManagement,
    membershipManagement,
    subscriptionManagement,
});

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT") {
        Object.keys(state).forEach((key) => {
            storage.removeItem(`persist:${key}`);
        });
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
