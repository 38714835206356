import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const employerManagementSlide = createSlice({
    name: "employerManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,

        updateEmployerStatusSuccess: false,
        updateEmployerStatusFail: false,
    },
    reducers: {
        doGetEmployerManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
        },
        doGetEmployerManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetEmployerManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetEmployerManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateEmployerStatusSuccess: (state, action) => {
            state.updateEmployerStatusSuccess = true;
            state.updateEmployerStatusFail = false;
        },
        doUpdateEmployerStatusFail: (state, action) => {
            state.updateEmployerStatusSuccess = false;
            state.updateEmployerStatusFail = true;
			state.errorDetailData = action.payload;
        },
        resetEmployerManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
        resetUpdateEmployerStatusStateSuccess: (state, action) => {
            state.updateEmployerStatusSuccess = action.payload;
            state.updateEmployerStatusFail = action.payload;
        },
    },
});

export const doGetEmployerManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/employer-management/list?page=${param?.page}&size=${param?.limit}`;
        if (param?.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterMo') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetEmployerManagementListSuccess(response?.data));
    } catch (error) {
        dispatch(doGetEmployerManagementListFail(error?.response));
    }
};

export const doGetEmployerManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/employer-management/${param?.employer_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetEmployerManagementDetailSuccess(response?.data));
    } catch (error) {
        dispatch(doGetEmployerManagementDetailFail(error?.response));
    }
};

export const doUpdateEmployerStatus = (param, employerId) => async (dispatch) => {
    try {
        const url = `/api/admin/employer-management/${employerId}/status`;
        const response = await axios.put(url, param);
        dispatch(doUpdateEmployerStatusSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateEmployerStatusFail(error.response));
    }
};

export const resetEmployerManagementState = () => async (dispatch) => {
    dispatch(resetEmployerManagementStateSuccess(false));
 };

 export const resetUpdateEmployerStatusState = () => async (dispatch) => {
    dispatch(resetUpdateEmployerStatusStateSuccess(false));
};

export const { 
    doGetEmployerManagementListSuccess, 
    doGetEmployerManagementListFail, 
    doGetEmployerManagementDetailSuccess,
    doGetEmployerManagementDetailFail,
    doUpdateEmployerStatusSuccess,
    doUpdateEmployerStatusFail,
    resetEmployerManagementStateSuccess,
    resetUpdateEmployerStatusStateSuccess
} = employerManagementSlide.actions;

export const getEmployerManagementList = (state) => state.employerManagement?.listData;
export const getEmployerManagementListPage = (state) => state.employerManagement?.page;
export const getEmployerManagementListPageCount = (state) => state.employerManagement?.pageCount;
export const getEmployerManagementDetail = (state) => state.employerManagement?.detailData;
export const getEmployerManagementErrorDetailData = (state) => state.employerManagement?.errorDetailData;

export const getUpdateEmployerStatusSuccess = (state) => state.employerManagement?.updateEmployerStatusSuccess;
export const getUpdateEmployerStatusFail = (state) => state.employerManagement?.updateEmployerStatusFail;

export default employerManagementSlide.reducer;
