import React from "react";

const MembershipManagementList = React.lazy(() => import('src/pages/membership_management/MembershipManagementList'));
const ViewMembershipManagement = React.lazy(() => import('src/pages/membership_management/ViewMembershipManagement'));

const routes = [
    { path: '/dashboard/membership-management-list/view', withParam: true, exact: true, name: "View", element: ViewMembershipManagement },
    { path: '/dashboard/membership-management-list', name: 'Membership Management', element: MembershipManagementList },
];

export default routes;