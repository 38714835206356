import React from "react";

const SubscriptionManagementList = React.lazy(() => import('src/pages/subscription_management/SubscriptionManagementList'));
const ViewSubscriptionManagement = React.lazy(() => import('src/pages/subscription_management/ViewSubscriptionManagement'));
const AddSubscriptionManagement = React.lazy(() => import('src/pages/subscription_management/AddSubscriptionManagement'));
const EditSubscriptionManagement = React.lazy(() => import('src/pages/subscription_management/EditSubscriptionManagement'));

const routes = [
    { path: '/dashboard/subscription-management-list/edit', name: 'Edit', element: EditSubscriptionManagement },
    { path: '/dashboard/subscription-management-list/add', name: 'Add', element: AddSubscriptionManagement },
    { path: '/dashboard/subscription-management-list/view', withParam: true, exact: true, name: "View", element: ViewSubscriptionManagement },
    { path: '/dashboard/subscription-management-list', name: 'Subscription Management', element: SubscriptionManagementList },
];

export default routes;