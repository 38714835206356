import React from "react";

const UniversityManagementList = React.lazy(() => import('src/pages/university_management/UniversityManagementList'));
const ViewUniversityManagement = React.lazy(() => import('src/pages/university_management/ViewUniversityManagement'));

const routes = [
    { path: '/dashboard/university-management-list/view', withParam: true, exact: true, name: "View", element: ViewUniversityManagement },
    { path: '/dashboard/university-management-list', name: 'University Management', element: UniversityManagementList },
];

export default routes;