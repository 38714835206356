import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const membershipManagementSlide = createSlice({
    name: "membershipManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
    },
    reducers: {
        doGetMembershipManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
        },
        doGetMembershipManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetMembershipManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetMembershipManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        
        resetMembershipManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetMembershipManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/membership-management/list?page=${param?.page}&size=${param?.limit}`;
        if (param?.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterMo') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetMembershipManagementListSuccess(response?.data));
    } catch (error) {
        dispatch(doGetMembershipManagementListFail(error?.response));
    }
};

export const doGetMembershipManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/membership-management/${param?.package_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetMembershipManagementDetailSuccess(response?.data));
    } catch (error) {
        dispatch(doGetMembershipManagementDetailFail(error?.response));
    }
};

export const resetMembershipManagementState = () => async (dispatch) => {
    dispatch(resetMembershipManagementStateSuccess(false));
 };

export const { 
    doGetMembershipManagementListSuccess, 
    doGetMembershipManagementListFail, 
    doGetMembershipManagementDetailSuccess,
    doGetMembershipManagementDetailFail,
    resetMembershipManagementStateSuccess,
} = membershipManagementSlide.actions;

export const getMembershipManagementList = (state) => state.membershipManagement?.listData;
export const getMembershipManagementListPage = (state) => state.membershipManagement?.page;
export const getMembershipManagementListPageCount = (state) => state.membershipManagement?.pageCount;
export const getMembershipManagementDetail = (state) => state.membershipManagement?.detailData;
export const getMembershipManagementErrorDetailData = (state) => state.membershipManagement?.errorDetailData;

export default membershipManagementSlide.reducer;
