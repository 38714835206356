import React from "react";

const JobManagementList = React.lazy(() => import('src/pages/job_management/JobManagementList'));
const ViewJobManagement = React.lazy(() => import('src/pages/job_management/ViewJobManagement'));

const routes = [
    { path: '/dashboard/job-management-list/view', withParam: true, exact: true, name: "View", element: ViewJobManagement },
    { path: '/dashboard/job-management-list', name: 'Job Management', element: JobManagementList },
];

export default routes;