import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const jobManagementSlide = createSlice({
    name: "jobManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,

        updateJobStatusSuccess: false,
        updateJobStatusFail: false,
    },
    reducers: {
        doGetJobManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
        },
        doGetJobManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetJobManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetJobManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateJobStatusSuccess: (state, action) => {
            state.updateJobStatusSuccess = true;
            state.updateJobStatusFail = false;
        },
        doUpdateJobStatusFail: (state, action) => {
            state.updateJobStatusSuccess = false;
            state.updateJobStatusFail = true;
			state.errorDetailData = action.payload;
        },
        resetJobManagementStateSuccess: (state, action) => {
            state.detailData = null;
			state.errorDetailData = null;
        },
        resetUpdateJobStatusStateSuccess: (state, action) => {
            state.updateJobStatusSuccess = action.payload;
            state.updateJobStatusFail = action.payload;
        },
    },
});

export const doGetJobManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/job-management/list?page=${param?.page}&size=${param?.limit}`;
        if (param?.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterEmployer') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetJobManagementListSuccess(response?.data));
    } catch (error) {
        dispatch(doGetJobManagementListFail(error?.response));
    }
};

export const doGetJobManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/job-management/${param?.job_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetJobManagementDetailSuccess(response?.data));
    } catch (error) {
        dispatch(doGetJobManagementDetailFail(error?.response));
    }
};

export const doUpdateJobStatus = (param, jobId) => async (dispatch) => {
    try {
        const url = `/api/admin/job-management/${jobId}/status`;
        const response = await axios.put(url, param);
        dispatch(doUpdateJobStatusSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateJobStatusFail(error.response));
    }
};

export const resetJobManagementState = () => async (dispatch) => {
    dispatch(resetJobManagementStateSuccess(false));
};

export const resetUpdateJobStatusState = () => async (dispatch) => {
    dispatch(resetUpdateJobStatusStateSuccess(false));
};

export const { 
    doGetJobManagementListSuccess, 
    doGetJobManagementListFail, 
    doGetJobManagementDetailSuccess,
    doGetJobManagementDetailFail,
    doUpdateJobStatusSuccess,
    doUpdateJobStatusFail,
    resetJobManagementStateSuccess,
    resetUpdateJobStatusStateSuccess,
} = jobManagementSlide.actions;

export const getJobManagementList = (state) => state.jobManagement?.listData;
export const getJobManagementListPage = (state) => state.jobManagement?.page;
export const getJobManagementListPageCount = (state) => state.jobManagement?.pageCount;
export const getJobManagementDetail = (state) => state.jobManagement?.detailData;
export const getJobManagementErrorDetailData = (state) => state.jobManagement?.errorDetailData;

export const getUpdateJobStatusSuccess = (state) => state.jobManagement?.updateJobStatusSuccess;
export const getUpdateJobStatusFail = (state) => state.jobManagement?.updateJobStatusFail;

export default jobManagementSlide.reducer;
