import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const subscriptionManagementSlide = createSlice({
    name: "subscriptionManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,

        addSubscriptionSuccess: false,
        addSubscriptionFail: false,

        renewSubscriptionSuccess: false,
        renewSubscriptionFail: false,
    },
    reducers: {
        doGetSubscriptionManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
        },
        doGetSubscriptionManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetSubscriptionManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetSubscriptionManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddSubscriptionSuccess: (state, action) => {
            state.addSubscriptionSuccess = true;
            state.addSubscriptionFail = false;
        },
        doAddSubscriptionFail: (state, action) => {
            state.addSubscriptionSuccess = false;
            state.addSubscriptionFail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateSubscriptionSuccess: (state, action) => {
            state.addSubscriptionSuccess = true;
            state.addSubscriptionFail = false;
        },
        doUpdateSubscriptionFail: (state, action) => {
            state.addSubscriptionSuccess = false;
            state.addSubscriptionFail = true;
			state.errorDetailData = action.payload;
        },
        doRenewSubscriptionSuccess: (state, action) => {
            state.renewSubscriptionSuccess = true;
            state.renewSubscriptionFail = false;
        },
        doRenewSubscriptionFail: (state, action) => {
            state.renewSubscriptionSuccess = false;
            state.renewSubscriptionFail = true;
			state.errorDetailData = action.payload;
        },
        resetSubscriptionManagementStateSuccess: (state, action) => {
            state.detailData = null;
			state.errorDetailData = null;
        },
        resetAddSubscriptionStateSuccess: (state, action) => {
            state.addSubscriptionSuccess = action.payload;
            state.addSubscriptionFail = action.payload;
            state.renewSubscriptionSuccess = action.payload;
            state.renewSubscriptionFail = action.payload;
        },
    },
});

export const doGetSubscriptionManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/subscription-management/list?page=${param?.page}&size=${param?.limit}`;
        if (param?.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterEmployer') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetSubscriptionManagementListSuccess(response?.data));
    } catch (error) {
        dispatch(doGetSubscriptionManagementListFail(error?.response));
    }
};

export const doGetSubscriptionManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/subscription-management/${param?.subscription_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetSubscriptionManagementDetailSuccess(response?.data));
    } catch (error) {
        dispatch(doGetSubscriptionManagementDetailFail(error?.response));
    }
};

export const doAddSubscription = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/subscription-management`;
        const response = await axios.post(url, param);
        dispatch(doAddSubscriptionSuccess(response.data));
    } catch (error) {
        dispatch(doAddSubscriptionFail(error.response));
    }
};

export const doUpdateSubscription = (param, subscriptionId) => async (dispatch) => {
    try {
        const url = `/api/admin/subscription-management/${subscriptionId}`;
        const response = await axios.put(url, param);
        dispatch(doUpdateSubscriptionSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateSubscriptionFail(error.response));
    }
};

export const doRenewSubscription = (subscriptionId) => async (dispatch) => {
    try {
        const url = `/api/admin/subscription-management/${subscriptionId}/renew`;
        const response = await axios.put(url);
        dispatch(doRenewSubscriptionSuccess(response.data));
    } catch (error) {
        dispatch(doRenewSubscriptionFail(error.response));
    }
};

export const resetSubscriptionManagementState = () => async (dispatch) => {
    dispatch(resetSubscriptionManagementStateSuccess(false));
};

export const resetAddSubscriptionState = () => async (dispatch) => {
    dispatch(resetAddSubscriptionStateSuccess(false));
};

export const { 
    doGetSubscriptionManagementListSuccess, 
    doGetSubscriptionManagementListFail, 
    doGetSubscriptionManagementDetailSuccess,
    doGetSubscriptionManagementDetailFail,
    doAddSubscriptionSuccess,
    doAddSubscriptionFail,
    doUpdateSubscriptionSuccess,
    doUpdateSubscriptionFail,
    doRenewSubscriptionSuccess,
    doRenewSubscriptionFail,
    resetSubscriptionManagementStateSuccess,
    resetAddSubscriptionStateSuccess,
} = subscriptionManagementSlide.actions;

export const getSubscriptionManagementList = (state) => state.subscriptionManagement?.listData;
export const getSubscriptionManagementListPage = (state) => state.subscriptionManagement?.page;
export const getSubscriptionManagementListPageCount = (state) => state.subscriptionManagement?.pageCount;
export const getSubscriptionManagementDetail = (state) => state.subscriptionManagement?.detailData;
export const getSubscriptionManagementErrorDetailData = (state) => state.subscriptionManagement?.errorDetailData;

export const getAddSubscriptionSuccess = (state) => state.subscriptionManagement?.addSubscriptionSuccess;
export const getAddSubscriptionFail = (state) => state.subscriptionManagement?.addSubscriptionFail;

export const getRenewSubscriptionSuccess = (state) => state.subscriptionManagement?.renewSubscriptionSuccess;
export const getRenewSubscriptionFail = (state) => state.subscriptionManagement?.renewSubscriptionFail;

export default subscriptionManagementSlide.reducer;