import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const persistor = persistStore(store);

let theme = createTheme({
    typography: {
        fontFamily: ["Work Sans", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#005e99",
        },
        secondary: {
            main: "#fedf02",
        },
        white: {
            main: "##005e99",
            overrides: {
                MuiButton: {
                    label: {
                        color: "#ffffff",
                    },
                },
            },
        },
        black: {
            main: "#24272D",
        },
        grey: {
            main: "#2C384AAE",
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "blue" },
                    style: {
                        backgroundColor: "#007bc8",
                        color: "#ffffff",
                        fontWeight: 700,
                        textShadow: "0 1px 1px #005e99",
                        transition: "all .35s",
                        "&:hover": {
                            background: "#005e99",
                        },
                    },
                },
            ],
        },
    },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
