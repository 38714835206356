import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const myProfileSlide = createSlice({
    name: "myProfile",
    initialState: {
        success: false,
        fail: false,
        errorDetailData: null,
    },
    reducers: {
        doChangePasswordSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doChangePasswordFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload.data;
        },
        doUpdateMeSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateMeFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload.data;
        },
        resetChangePasswordStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
        },
        resetMyProfileStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.errorDetailData = null;
        },
    },
});

export const doChangePassword = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/auth/password`;

        const response = await axios.put(url, param);
        dispatch(doChangePasswordSuccess(response.data));
    } catch (error) {
        dispatch(doChangePasswordFail(error.response));
    }
};

export const doUpdateMe = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/auth/me`;

        const response = await axios.put(url, param);
        dispatch(doUpdateMeSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateMeFail(error.response));
    }
};

export const resetChangePasswordState = () => async (dispatch) => {
    dispatch(resetChangePasswordStateSuccess(false));
 };

 export const resetMyProfileState = () => async (dispatch) => {
    dispatch(resetMyProfileStateSuccess(false));
 };

export const { 
    doChangePasswordSuccess,
    doChangePasswordFail,
    doUpdateMeSuccess,
    doUpdateMeFail,
    resetChangePasswordStateSuccess,
    resetMyProfileStateSuccess,
} = myProfileSlide.actions;

export const getChangePasswordSuccess = (state) => state.myProfile?.success;
export const getChangePasswordFail = (state) => state.myProfile?.fail;
export const getChangePasswordErrorDetailData = (state) => state.myProfile?.errorDetailData;
export const getUpdateMeSuccess = (state) => state.myProfile?.success;
export const getUpdateMeFail = (state) => state.myProfile?.fail;
export const getUpdateMeErrorDetailData = (state) => state.myProfile?.errorDetailData;

export default myProfileSlide.reducer;
