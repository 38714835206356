import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "./scss/style.scss";

// routes config
import adminRoute from "src/routes/admin_route";
import { USER_ROLE } from "src/utils/constant_user_role";
import { getLoggedIn } from "src/reducers/reducer_core";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Logout = React.lazy(() => import("./pages/logout/Logout"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));
const ForgotPassword = React.lazy(() => import("./pages/forgot_password/ForgotPassword"));
const ForgotPasswordSent = React.lazy(() => import("./pages/forgot_password_sent/ForgotPasswordSent"));
const ResetPassword = React.lazy(() => import("./pages/reset_password/ResetPassword"));
const ResetPasswordSuccess = React.lazy(() => import("./pages/reset_password_success/ResetPasswordSuccess"));

const App = () => {
    const loggedIn = useSelector(getLoggedIn);

    return (
        <HashRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route exact path="/logout" name="Login Page" element={<Logout />} />
                    <Route exact path="/login" name="Login Page" element={<Login />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
                    <Route exact path="/forgot-password-sent" name="Forgot Password Sent" element={<ForgotPasswordSent />} />
                    <Route exact path="/reset-password/:token" name="Reset Password" element={<ResetPassword />} />
                    <Route exact path="/reset-password-success" name="Reset Password Success" element={<ResetPasswordSuccess />} />
                    <Route path="dashboard" name="Dashboard" element={<DefaultLayout />}>
                        {loggedIn?.users?.roleName === USER_ROLE.ADMIN &&
                            adminRoute.map((route, idx) => {
                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                        })}
                    </Route>
                    <Route path="/" name="Dashboard" element={<DefaultLayout />} />
                    <Route path="*" name="Not Found" element={<Page404 />} />
                </Routes>
            </Suspense>
        </HashRouter>
    );
};

export default App;
