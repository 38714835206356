import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const forgotPasswordSlide = createSlice({
    name: "forgotPassword",
    initialState: {
        success: false,
        fail: false,
        errorDetailData: null,
    },
    reducers: {
        doSendForgotPasswordSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doSendForgotPasswordFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload.data;
        },
        doSendResetPasswordSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doSendResetPasswordFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload.data;
        },
        resetForgotPasswordStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.errorDetailData = null;
        },
    },
});

export const doForgotPassword = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/auth/forgot-password`;

        const response = await axios.post(url, param);
        dispatch(doSendForgotPasswordSuccess(response.data));
    } catch (error) {
        dispatch(doSendForgotPasswordFail(error.response));
    }
};

export const doResetPassword = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/auth/update-reset-password`;

        const response = await axios.post(url, param);
        dispatch(doSendResetPasswordSuccess(response.data));
    } catch (error) {
        dispatch(doSendResetPasswordFail(error.response));
    }
};

export const resetForgotPasswordState = () => async (dispatch) => {
    dispatch(resetForgotPasswordStateSuccess(false));
};

export const { 
    doSendForgotPasswordSuccess,
    doSendForgotPasswordFail,
    doSendResetPasswordSuccess,
    doSendResetPasswordFail,
    resetForgotPasswordStateSuccess,
} = forgotPasswordSlide.actions;

export const getForgotPasswordSuccess = (state) => state.forgotPassword?.success;
export const getForgotPasswordErrorDetailData = (state) => state.forgotPassword?.errorDetailData;
export const getResetPasswordSuccess = (state) => state.forgotPassword?.success;
export const getResetPasswordErrorDetailData = (state) => state.forgotPassword?.errorDetailData;

export default forgotPasswordSlide.reducer;
