import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const coreSlide = createSlice({
    name: "core",
    initialState: {
        sidebarShow: true,
        commonData: null,
        loggedIn: false,
        urlParam: null,
        loginFailedDetail: null,
        loginSuccess: false,
        logoutSuccess: false,
        logoutFail: false,
    },
    reducers: {
        setSideBarShow: (state, action) => {
            state.sidebarShow = action.payload;
        },
        setUrlParam: (state, action) => {
            state.urlParam = action.payload;
        },
        setCommonData: (state, action) => {
            state.commonData = action.payload.meta;
        },
        doLoginSuccess: (state, action) => {
            state.user = action.payload.data;
            state.loginSuccess = true;
            state.logoutSuccess = false;
            state.loginFailedDetail = null;
        },
        doLoginFail: (state, action) => {
            state.loggedIn = null;
            state.loginSuccess = false;
            state.loginFailedDetail = action.payload.data;
        },
        resetLoginFail: (state, action) => {
            state.loginFailedDetail = null;
        },
        doLoggedInSuccess: (state, action) => {
            state.loggedIn = action.payload;
        },
        doLoggedInFail: (state, action) => {
            state.loggedIn = null;
        },
        doLogoutSuccess: (state, action) => {
            state.loggedIn = null;
            state.loginSuccess = false;
            state.logoutSuccess = true;
            state.logoutFail = false;
        },
        doLogoutFail: (state, action) => {
            state.logoutSuccess = false;
            state.logoutFail = true;
        },
    },
});

export const doLogin = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/auth/login`;
        const response = await axios.post(url, param);
        dispatch(doLoginSuccess(response.data));
    } catch (error) {
        dispatch(doLoginFail(error.response));
    }
};

export const doGetCommonData = () => async (dispatch) => {
    try {
        const response = await axios.get("/api/admin/commons/meta");
        dispatch(setCommonData({ meta: response.data }));
    } catch (error) {
        dispatch(setCommonData({ meta: null }));
    }
};

export const doGetLoggedInUserDetails = () => async (dispatch) => {
	try {
        const url = `/api/admin/auth/me`;
        const response = await axios.get(url);
        dispatch(doLoggedInSuccess(response.data));
    } catch (error) {
        dispatch(doLoggedInFail(error.response));
    }
};

export const doLogout = () => async (dispatch) => {
    try {
        const url = `/api/admin/auth/logout`;
        const response = await axios.get(url);
        dispatch(doLogoutSuccess(response.data));
    } catch (error) {
        dispatch(doLogoutFail(error.response));
    }
};

export const doSetUrlParam = (param) => async (dispatch) => {
   dispatch(setUrlParam(param));
};

export const updateSidebarShow = (param) => async (dispatch) => {
    dispatch(setSideBarShow(param));
 };

export const { 
    setSideBarShow,
    setCommonData, 
    doLoginSuccess, 
    setUrlParam, 
    doLoginFail, 
    doLoggedInSuccess, 
    doLoggedInFail, 
    resetLoginFail,
    doLogoutSuccess,
    doLogoutFail,
} = coreSlide.actions;

export const getSideBarShow = (state) => state.core?.sidebarShow;
export const getCommonData = (state) => state.core?.commonData;
export const getLoginFailedDetail = (state) => state.core?.loginFailedDetail;
export const getLoginSuccess = (state) => state.core?.loginSuccess;
export const getLoggedIn = (state) => state.core?.loggedIn;
export const getLogoutSuccess = (state) => state.core?.logoutSuccess;
export const getUrlParam = (state) => state.core?.urlParam;

export default coreSlide.reducer;
