import React from "react";

const EmployerManagementList = React.lazy(() => import('src/pages/employer_management/EmployerManagementList'));
const ViewEmployerManagement = React.lazy(() => import('src/pages/employer_management/ViewEmployerManagement'));

const routes = [
    { path: '/dashboard/employer-management-list/view', withParam: true, exact: true, name: "View", element: ViewEmployerManagement },
    { path: '/dashboard/employer-management-list', name: 'Employer Management', element: EmployerManagementList },
];

export default routes;