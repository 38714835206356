import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const studentManagementSlide = createSlice({
    name: "studentManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,

        editStudentManagementDetailSuccess: false,
        editStudentManagementDetailFail: false,
        editStudentManagementErrorDetail: null,
    },
    reducers: {
        doGetStudentManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
        },
        doGetStudentManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetStudentManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetStudentManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        resetStudentManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
        },
        doEditStudentManagementDetailSuccess: (state, action) => {
            state.editStudentManagementDetailSuccess = true;
            state.editStudentManagementDetailFail = false;
			state.editStudentManagementErrorDetail = null;
        },
        doEditStudentManagementDetailFail: (state, action) => {
            state.editStudentManagementDetailSuccess = false;
            state.editStudentManagementDetailFail = true;
			state.editStudentManagementErrorDetail = action.payload.data;
        },
        resetEditStudentManagementDetail: (state, action) => {
            state.editStudentManagementDetailSuccess = false;
            state.editStudentManagementDetailFail = false;
			state.editStudentManagementErrorDetail = null;
        },
    },
});

export const doGetStudentManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/student-management/list?page=${param?.page}&size=${param?.limit}`;
        if (param?.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterMo') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetStudentManagementListSuccess(response?.data));
    } catch (error) {
        dispatch(doGetStudentManagementListFail(error?.response));
    }
};

export const doGetStudentManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/student-management/${param?.student_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetStudentManagementDetailSuccess(response?.data));
    } catch (error) {
        dispatch(doGetStudentManagementDetailFail(error?.response));
    }
};

export const doEditStudentManagementDetail = (param, studentId) => async (dispatch) => {
    try {
        const url = `/api/admin/student-management/${studentId}/edit`;
        const response = await axios.put(url, param);
        dispatch(doEditStudentManagementDetailSuccess(response.data));
    } catch (error) {
        dispatch(doEditStudentManagementDetailFail(error.response));
    }
};

export const resetStudentManagementState = () => async (dispatch) => {
    dispatch(resetStudentManagementStateSuccess(false));
 };

 export const doResetEditStudentManagementDetailState = () => async (dispatch) => {
    dispatch(resetEditStudentManagementDetail());
};

export const { 
    doGetStudentManagementListSuccess, 
    doGetStudentManagementListFail, 
    doGetStudentManagementDetailSuccess,
    doGetStudentManagementDetailFail,
    resetStudentManagementStateSuccess,
    doEditStudentManagementDetailSuccess,
    doEditStudentManagementDetailFail,
    resetEditStudentManagementDetail
} = studentManagementSlide.actions;

export const getStudentManagementList = (state) => state.studentManagement?.listData;
export const getStudentManagementListPage = (state) => state.studentManagement?.page;
export const getStudentManagementListPageCount = (state) => state.studentManagement?.pageCount;
export const getStudentManagementDetail = (state) => state.studentManagement?.detailData;

export const getEditStudentManagementDetailSuccess = (state) => state.studentManagement?.editStudentManagementDetailSuccess;
export const getEditStudentManagementDetailFail = (state) => state.studentManagement?.editStudentManagementDetailFail;
export const getEditStudentManagementErrorDetail = (state) => state.studentManagement?.editStudentManagementErrorDetail;

export default studentManagementSlide.reducer;
