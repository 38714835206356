import employer_management_route from "./modules/employer_management_route";
import my_profile_route from "./modules/my_profile_route";
import university_management_route from "./modules/university_management_route";
import job_management_route from "./modules/job_management_route";
import membership_management_route from "./modules/membership_management_route";
import subscription_management_route from "./modules/subscription_management_route";
import student_management_route from "./modules/student_management_route";

const routes = [
    ...employer_management_route, 
    ...my_profile_route, 
    ...university_management_route, 
    ...job_management_route,
    ...membership_management_route,
    ...subscription_management_route,
    ...student_management_route,
];

export default routes;